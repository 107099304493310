import React from "react";
import linkedIn from "../../assets/linkedin-logo-contact.svg";
import github from "../../assets/github-logo-contact.svg";
import mail from "../../assets/email-logo.svg";

const Contact = () => {
    return (
        <div className="contact__container">
            <div className="container">
                <div className="row">
                    <div className="contact__name">
                        <span className="black">SOPHIE</span>
                        <span className="medium-grey">GRANDPERRIN</span>
                    </div>
                    <div className=" d-flex">
                        <a
                            href="https://www.linkedin.com/in/sophie-gauthier/"
                            className="contact__logos"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={linkedIn} alt="linkedin link" />
                        </a>
                        <a
                            href="https://github.com/sophieSpacee"
                            className="contact__logos"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={github} alt="github link" />
                        </a>
                        <a
                            href="mailto:sophie.grandperrin1@gmail.com"
                            className="contact__logos"
                        >
                            <img src={mail} alt="email me" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
