import React, { useState } from 'react';
import spacee from '../../assets/spacee-resume.png';
import wip from '../../assets/wip.svg';
import bearing from '../../assets/bearing.png';
import addworking from '../../assets/addworking.svg';
import linkedin from '../../assets/linkedin-logo-resume.svg';
import pdf from '../../assets/pdf-logo.svg';
import abodu from '../../assets/abodu.jpeg';

const Resume = () => {
  const [showMoreBearing, setShowMoreBearing] = useState(false);
  const [showMoreWip, setShowMoreWip] = useState(false);
  const [showMoreAddworking, setShowMoreAddworking] = useState(false);
  const [showMoreSpacee, setShowMoreSpacee] = useState(false);

  const goToLinkedIn = () => {
    window.open('https://www.linkedin.com/in/sophie-gauthier/');
  };

  return (
    <div className="resume__container">
      <div className="container">
        <h1>
          My <span className="h1__blue-underline">resume</span>
          <img
            src={linkedin}
            alt="linkedin link"
            className="resume__icons"
            onClick={goToLinkedIn}
          />
          <a
            href="https://sophiegrandperrin.com/resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={pdf}
              alt="pdf link to download resume"
              className="resume__icons"
              download
            />
          </a>
        </h1>
        <div className="row resume__company">
          <div className="col-3">
            <div className="resume__logo-container">
              <img
                src={abodu}
                alt="addworking logo"
                className="experience__logo"
              />
            </div>
          </div>
          <div className="col-9">
            <h2>
              Construction Project Manager at{' '}
              <a
                href="https://www.abodu.com/"
                className="experience__company"
                rel="noreferrer"
                target="_blank"
              >
                Abodu
              </a>
              <span className="h2__light"> Redwood City, CA</span>
            </h2>
            <p className="experience__date">January 2023 - Today</p>
            <p className="skills__description">
              Abodu is a start up that has been thriving at delivering ADUs in
              the Bay Area. I joined them as a Construction Project Manager to
              help deliver over 30 units in the Bay Area.
            </p>
            {showMoreAddworking ? (
              <div className="experience__details">
                <ul>
                  <li>Managed over 20 projects at a time.</li>
                  <li>
                    Created and kept track of my projects' budget and schedule.
                  </li>
                  <li>
                    Updated the customer at every step of the process, acted as
                    the only point of contact for the homeowner.
                  </li>
                  <li>
                    Sent change orders when there was a scope change, or a city
                    requirement that we could not anticipate.
                  </li>
                  <li>
                    Hired general contractor, crane operator and third parties
                    needed for each project.
                  </li>
                  <li>
                    Scheduled and participated in building inspection with the
                    city.
                  </li>
                  <li>
                    Worked in close relation with PG&E when a panel upgrade was
                    needed.
                  </li>
                </ul>

                <p
                  className="experience__seemore"
                  onClick={() => setShowMoreAddworking(false)}
                >
                  see less
                </p>
              </div>
            ) : (
              <p
                className="experience__seemore"
                onClick={() => setShowMoreAddworking(true)}
              >
                see more...
              </p>
            )}
          </div>
        </div>
        <div className="row resume__company">
          <div className="col-3">
            <div className="resume__logo-container">
              <img
                src={addworking}
                alt="addworking logo"
                className="experience__logo"
              />
            </div>
          </div>
          <div className="col-9">
            <h2>
              Full stack developer at{' '}
              <a
                href="https://en.addworking.com/"
                className="experience__company"
                rel="noreferrer"
                target="_blank"
              >
                Addworking
              </a>
              <span className="h2__light"> Remote</span>
            </h2>
            <p className="experience__date">September 2021 - Today</p>
            <p className="skills__description">
              Addworking is a SaaS solution for Real Estate professionals. I
              joined Addworking as a Full Stack developer (React / Node.js). As
              part of a scrum team, my job is to participate in developing the
              new version of our application using React and node.js. I also
              create designs when needed using Figma.
            </p>
            {showMoreAddworking ? (
              <div className="experience__details">
                <ul>
                  <li>
                    Transformed an old Laravel onboarding process into a new
                    React micro service to onboard subcontractors onto our
                    platform (SSO, translation, connection with a French
                    government business lookup API, sign up form…)
                  </li>
                  <li>
                    Worked in an Agile environment, with 5 other developers, on
                    a 2 weeks sprints basis
                  </li>
                  <li>
                    Created and maintained project tasks, provided programming
                    estimates, identified potential problems
                  </li>
                  <li>
                    Worked in close relation with designer, product owner and
                    devops
                  </li>
                  <li>
                    Implemented Google SSO and authentification with AWS Cognito
                  </li>
                  <li>Integrated i18n to translate the application</li>
                  <li>
                    Worked in close relation with designer, product owner and
                    devops
                  </li>
                  <li>Stack: React, Node.js, MongoDB, PostgreSQL, Figma</li>
                </ul>

                <p
                  className="experience__seemore"
                  onClick={() => setShowMoreAddworking(false)}
                >
                  see less
                </p>
              </div>
            ) : (
              <p
                className="experience__seemore"
                onClick={() => setShowMoreAddworking(true)}
              >
                see more...
              </p>
            )}
          </div>
        </div>

        <div className="row resume__company">
          <div className="col-3">
            <div className="resume__logo-container">
              <img
                src={spacee}
                alt="addworking logo"
                className="experience__logo"
              />
            </div>
          </div>
          <div className="col-9">
            <h2>
              Founder, Product Manager and Full stack developer at{' '}
              <a
                href="https://spacee-workplace.com"
                className="experience__company"
                rel="noreferrer"
                target="_blank"
              >
                Spacee
              </a>
              <span className="h2__light"> Paris, France</span>
            </h2>
            <p className="experience__date">January - September-2021</p>
            <p className="skills__description">
              Spacee is designed to simplify a workplace project. Its goal is to
              help the company build a solid and complete program along with a
              final macrozoning based on space constraints and teams synergies.
              I created Spacee in 2020, after realizing the real estate market's
              lack of SaaS solutions.
            </p>
            {showMoreSpacee ? (
              <div className="experience__details">
                <ul>
                  <li>
                    Imagined a SaaS application to help professionals manage
                    their real estate projects
                  </li>
                  <li>
                    Created the mock up and design on Figma, drew the database
                    architecture and tables
                  </li>
                  <li>
                    Developed the application with React, node.js/express and
                    MySQL/Sequelize
                  </li>
                  <li>
                    Learned to respect a design with pixel-perfect precision and
                    develop fully responsive websites
                  </li>
                  <li>
                    Learned how to optimise a website’s SEO while respecting
                    WCAG guidelines as well as OWASP principles
                  </li>
                  <li>Created, connected and used REST APIs </li>
                  <li>
                    Created, maintained and used data from a database (MongoDB,
                    MySQL) using appropriate ORM (Mongoose, Sequelize)
                  </li>
                </ul>

                <p
                  className="experience__seemore"
                  onClick={() => setShowMoreSpacee(false)}
                >
                  see less
                </p>
              </div>
            ) : (
              <p
                className="experience__seemore"
                onClick={() => setShowMoreSpacee(true)}
              >
                see more...
              </p>
            )}
          </div>
        </div>

        <div className="row resume__company">
          <div className="col-3">
            <div className="resume__logo-container">
              <img
                src={wip}
                alt="workinprogress logo"
                className="experience__logo"
              />
            </div>
          </div>
          <div className="col-9">
            <h2>
              Real Estate Project Manager at{' '}
              <a
                href="https://www.workinprogress.xyz/"
                className="experience__company"
                rel="noreferrer"
                target="_blank"
              >
                workINprogress
              </a>
              <span className="h2__light"> Paris, France</span>
            </h2>
            <p className="experience__date">October 2018 - January 2021</p>
            <p className="skills__description">
              workINprogress is a consulting company specialized in real estate
              projects. I joined as a workplace consultant and moved to a
              project manager position a year later. I was in charge of several
              real estate projects with clients such as Orange, LVMH, Thalès,
              Dior.
            </p>
            {showMoreWip ? (
              <div className="experience__details">
                <ul>
                  <li>Managed several long-term real estate projects</li>
                  <li>
                    Organized over 10 seminars with an average of 200
                    participants: presentation, workshops, live speakers
                  </li>
                  <li>
                    Coordinated project's stakeholders (architects, technical
                    consultants, client) and ensure a good collaboration
                  </li>
                  <li>Defined strategic guidelines with executives</li>
                  <li>Conducted individual and group interviews</li>
                  <li>
                    Created and maintained schedule and action plan with Gantt
                    Project
                  </li>
                  <li>
                    Managed a team of consultant to design and deliver quality
                    workshops
                  </li>
                  <li>Ensured deadlines respect </li>
                </ul>
                <p
                  className="experience__seemore"
                  onClick={() => setShowMoreWip(false)}
                >
                  see less
                </p>
              </div>
            ) : (
              <p
                className="experience__seemore"
                onClick={() => setShowMoreWip(true)}
              >
                see more...
              </p>
            )}
          </div>
        </div>

        <div className="row resume__company">
          <div className="col-3">
            <div className="resume__logo-container">
              <img
                src={bearing}
                alt="bearing Point logo"
                className="experience__logo"
              />
            </div>
          </div>
          <div className="col-9">
            <h2>
              Consultant - Real Estate Practice at{' '}
              <a
                href="https://www.bearingpoint.com/en/"
                className="experience__company"
                rel="noreferrer"
                target="_blank"
              >
                Bearing Point
              </a>
              <span className="h2__light"> Paris, France</span>
            </h2>
            <p className="experience__date">January 2017 - September 2018</p>
            <p className="skills__description">
              BearingPoint is an international consulting firm. I joined the
              real estate team and worked on various projects, from helping a
              company improve its customer relation to writing the functional
              specifications for an HR software.
            </p>
            {showMoreBearing ? (
              <div className="experience__details">
                <ul>
                  <li>
                    Optimised customer relation for a CAC 40 insurance company
                  </li>
                  <li>
                    Conception of an internal CRM software for 70 000 employees
                    (Redaction of functional specifications, workshop
                    facilitation)
                  </li>
                  <li>
                    Payroll process mapping and improvement for a public company
                  </li>
                  <li>
                    Organized a 200 persons forum around new ways of working and
                    new workspace environments
                  </li>
                  <li>PMO of several transformation projects</li>
                  <li>Lead of individual interviews</li>
                </ul>
                <p
                  className="experience__seemore"
                  onClick={() => setShowMoreBearing(false)}
                >
                  see less
                </p>
              </div>
            ) : (
              <p
                className="experience__seemore"
                onClick={() => setShowMoreBearing(true)}
              >
                see more...
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
