import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./style/css/style.css";
import Website from "./components/Website/Website";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Website />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
