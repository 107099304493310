import React from 'react';
import smile from '../../assets/smile.jpeg';

const Skills = () => {
  return (
    <div className="skills__container">
      <div className="container ">
        <h1>
          Who <span className="h1__blue-underline">I am</span>
        </h1>
        <div className="row">
          <div className="col-lg-8 col-12">
            <p className="skills__description">
              With over 7 years of extensive experience in the real estate
              industry, I have cultivated a diverse and robust skill set. I
              began my career as a <u>Workplace Consultant</u>, followed by four
              years as a Workplace Project Manager in Paris. This experience
              provided a strong foundation that I built upon when I moved to San
              Francisco, where I transitioned into the role of{' '}
              <u>Construction Project Manager</u> for an ADU company.
              <br />
              <br />
              Currently, I serve as a Construction Project Manager at Abodu,
              where I manage up to 20 projects simultaneously. My journey has
              equipped me with exceptional organizational skills, a keen
              attention to detail, and a steadfast commitment to client
              satisfaction. I am adept at managing complex projects, ensuring
              they are completed on time, within budget, and to the highest
              quality standards.
            </p>
            <h2>Hard skills</h2>
            <div className="skills__skills-container">
              <div className="skills__skill">Bluebeam</div>
              <div className="skills__skill">Coconstruct</div>
              <div className="skills__skill">Power Point</div>
              <div className="skills__skill">Gantt Project</div>
              <div className="skills__skill">Google Suite</div>
              <div className="skills__skill">Microsoft Office</div>
              <div className="skills__skill">Fluent in French and english</div>
            </div>
            <h2>Soft skills</h2>
            <div className="skills__skills-container">
              <div className="skills__skill">Teamwork</div>
              <div className="skills__skill">Communication</div>
              <div className="skills__skill">Attention to detail</div>
              <div className="skills__skill">Perseverance</div>
              <div className="skills__skill">Organization</div>
              <div className="skills__skill">Positive energy</div>
            </div>

            <h2>What I like</h2>
            <div className="skills__skills-container">
              <div className="skills__skill">Snowboard</div>
              <div className="skills__skill">Squash</div>
              <div className="skills__skill">Climbing</div>
              <div className="skills__skill">Hiking</div>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div
              className="portfolio__image"
              style={{
                backgroundImage: `url(${smile})`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
