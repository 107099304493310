import React from "react";
import osha from "../../assets/osha.svg";
import coco from "../../assets/coco.svg";
import bluebeam from "../../assets/bluebeam.svg";
import msproject from "../../assets/msproject.svg";
import powerpoint from "../../assets/ppt-logo.svg";

const Logos = () => {
    return (
        <div className="logos__container">
            <div className="container ">
                <div className="row d-flex justify-content-between">
                    <div className="col logo">
                        <img src={osha} alt="osha logo" />
                    </div>
                    <div className="col   logo">
                        <img src={coco} alt="react logo" />
                    </div>
                    <div className="col  logo">
                        <img src={bluebeam} alt="sass logo" />
                    </div>
                    <div className="col  logo">
                        <img src={msproject} alt="github logo" />
                    </div>
                    <div className="col  logo">
                        <img src={powerpoint} alt="github logo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logos;
