import React from 'react';
import Carrousel from './Carrousel';
import happy from '../../assets/happy.svg';
import cash from '../../assets/cash.svg';
import house from '../../assets/house.svg';

const FirstYear = () => {
  return (
    <div className="firstyear__container">
      <div className="container">
        <div className="row">
          <h1>
            Results from my last year as a{' '}
            <span className="h1__blue-underline"> Project Manager</span>
          </h1>
        </div>
        <br />
        <div className="row mt-4">
          <div className="col">
            <div className="article__container">
              <div className="article__title">
                30+ ADUs delivered <img src={house} alt="rocket" />
              </div>
              <div className="article__underline"></div>
              <div className="article__content">
                I have delivered over 30 ADUs to families in the Bay Area,
                managing the entire process from breaking ground to key
                handover. Helping these families add an ADU to their backyard
                has been an incredible experience, as it significantly impacts
                their lives in a positive way.
              </div>
            </div>
          </div>
          <div className="col">
            <div className="article__container">
              <div className="article__title">
                8.5 NPS <img src={happy} alt="geek" />
              </div>
              <div className="article__underline"></div>
              <div className="article__content">
                My primary focus was ensuring that my clients had an exceptional
                experience. I prioritized their needs, consistently met their
                expectations, and maintained transparency to avoid any
                surprises. Their happiness was always my top priority. That is
                how I got an average 8.5/10 on my satisfaction survey.
              </div>
            </div>
          </div>
          <div className="col">
            <div className="article__container">
              <div className="article__title">
                Additional budget saved <img src={cash} alt="cash" />
              </div>
              <div className="article__underline"></div>
              <div className="article__content">
                When an item was added during the project due to city
                requirements or additional scope that couldn’t be anticipated in
                the initial phase, I made sure to send change orders to the
                client. I managed to deliver most of my projects under budget,
                saving extra money for the company.
              </div>
            </div>
          </div>
        </div>
        <Carrousel />
      </div>
    </div>
  );
};

export default FirstYear;
