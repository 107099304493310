import React from "react";
import hello from "../../assets/adu.jpeg";
import linkedin from "../../assets/linkedin-button.svg";

const Hello = () => {
    return (
      <div>
        <div className="grey-filter hello__text">
          <div
            style={{ backgroundImage: `url(${hello})` }}
            className="image__container"
          ></div>
          <div className="container">
            <div className="name">
              <span className="light-grey">SOPHIE</span>
              <span className="white">GRANDPERRIN</span>
            </div>
            <div className="hello__hi">
              Hi, I'm <span className="h1__blue-underline">Sophie</span>
            </div>
            <div className="hello__content">Real Estate Project Manager</div>
            <a
              href="https://www.linkedin.com/in/sophie-gauthier/"
              rel="noreferrer"
              target="_blank"
            >
              <div className="hello__github">
                <img src={linkedin} alt="linkedin link" />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
};

export default Hello;
