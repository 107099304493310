import React from "react";
import Contact from "../Contact/Contact";
import Resume from '../Resume/Resume_RE';
import Hello from '../Hello/Hello_RE';
import Logos from '../Logos/Logos_RE';
import FirstYear from '../FirstYear/FirstYear_RE';
import Skills from '../Skills/Skills_RE';

const Website = () => {
  return (
    <>
      <Hello />
      <Logos />
      <Skills />
      <FirstYear />
      <Resume />
      <Contact />
    </>
  );
};

export default Website;
