import React, { useEffect, useState } from 'react';

const reviews = [
  {
    name: 'Carol G.',
    citation: 'ADU delivered in 2023, San Jose',
    quote:
      'My project manager Sophie Gauthier was excellent, engaged, responsive and communicative. She appeared to work well with ground construction crew. ',
  },
  {
    name: 'Swaroop J.',
    citation: 'ADU delivered in 2023, Mountain View',
    quote: 'Sophie was awesome and made the whole process super easy for us!',
  },
  {
    name: 'Rajeev P.',
    citation: 'ADU delivered in 2023, Saratoga',
    quote:
      'Wonderful to work with Sophie. Also the staff who did the crane operating and laying down the foundation were excellent. Unfortunately the permitting issues caused a long delay. Again, we were glad Sophie could take over the project to get things moving. Beautiful design inside and out.',
  },
  {
    name: 'Kevin A.',
    citation: 'ADU delivered in 2022, San Jose',
    quote:
      "Having said all this, as soon as Sophie Gauthier took over our project, she was able to get everything under control with much better communication, and I appreciated that she took ownership and showed urgency to actually finish our project. I think Abodu customers who work with her from start to finish going forward will have a very positive experience. I'm happy to go into more detail, you can call me anytime. Overall the unit was great, the value is there, it was just the execution of the last stretch that was frustrating until Sophie took over.",
  },
  {
    name: 'Vyju R.',
    citation: 'ADU delivered in 2023, Saratoga',
    quote:
      'Sophie Gauthier has been such a pleasure to work with at Abodu. We have had some delays with permitting etc. and it really made a difference to have her as our project manager. She started with us a few months into the project. She was responsive, efficient, and made sure the details were all in order. The housing unit itself is well- built, beautiful and modern. Installation process went very smoothly. The contractors who have done the foundation, work, trenching, etc. are very competent and knowledgeable. Overall, I would recommend this company, especially if you can work with someone like Sophie.',
  },
];
const Carrousel = () => {
  const [activeSlide, setActiveSlide] = useState(1);
  useEffect(() => {
    // This variable prevents race condition
    let current = 1;
    const cycleReviews = () => {
      if (current === 5) {
        current = 1;
      } else {
        current += 1;
      }
      setActiveSlide(current);
    };
    // intervalId identified so it can be canceled on unmount
    const intervalId = setInterval(() => {
      cycleReviews();
    }, 10000);
    // Removes interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="App">
      <ul className="carousel__list">
        {reviews.map((review, index) => {
          const { citation, name, quote } = review;
          const count = index + 1;
          return (
            <li
              className={`carousel__item
                ${count === activeSlide ? ' active' : ''}
                ${count < activeSlide ? ' left' : ''}
                ${count > activeSlide ? ' right' : ''}
              `}
              key={count}
            >
              <blockquote className="carousel__quote">
                <cite>
                  <span className="carousel__name">{name}</span>
                  <span className="carousel__citation">{citation}</span>
                </cite>
                <p>"{quote}"</p>
              </blockquote>
            </li>
          );
        })}
        <li className="carousel__indicator">
          <span
            className={`carousel__dot${activeSlide === 1 ? ' active' : ''}`}
          />
          <span
            className={`carousel__dot${activeSlide === 2 ? ' active' : ''}`}
          />
          <span
            className={`carousel__dot${activeSlide === 3 ? ' active' : ''}`}
          />
          <span
            className={`carousel__dot${activeSlide === 4 ? ' active' : ''}`}
          />
          <span
            className={`carousel__dot${activeSlide === 5 ? ' active' : ''}`}
          />
        </li>
      </ul>
    </div>
  );
};

export default Carrousel;
